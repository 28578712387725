// extracted by mini-css-extract-plugin
export var alignDiscLeft = "v_sy d_fp d_bG d_dv";
export var alignLeft = "v_qd d_fp d_bG d_dv";
export var alignDiscCenter = "v_sz d_fq d_bD d_dw";
export var alignCenter = "v_bP d_fq d_bD d_dw";
export var alignDiscRight = "v_sB d_fr d_bH d_dx";
export var alignRight = "v_qf d_fr d_bH d_dx";
export var footerContainer = "v_sC d_dW d_bW";
export var footerContainerFull = "v_sD d_dT d_bW";
export var footerHeader = "v_kf d_kf";
export var footerTextWrapper = "v_sF d_w";
export var footerDisclaimerWrapper = "v_km d_km d_ck";
export var badgeWrapper = "v_sG d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "v_sH d_bz d_bJ d_bN d_bL";
export var wide = "v_sJ d_cy";
export var right = "v_sK d_bK";
export var line = "v_fk d_fl d_cv";
export var badgeDisclaimer = "v_sL d_bC d_bP d_bJ";
export var badgeContainer = "v_sM d_bz d_bH d_bP";
export var badge = "v_sN";
export var padding = "v_sP d_c7";
export var end = "v_sQ d_bH";
export var linkWrapper = "v_sR d_dB";
export var link = "v_mC d_dB";
export var colWrapper = "v_sS d_cx";
export var consent = "v_f d_f d_bC d_bP";
export var disclaimer = "v_sT d_bz d_bJ";
export var media = "v_sV d_bx d_dy";
export var itemRight = "v_sW";
export var itemLeft = "v_sX";
export var itemCenter = "v_sY";
export var exceptionWeight = "v_sZ y_tT";