// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "x_s7 d_gv d_cs";
export var heroHeaderCenter = "x_gw d_gw d_cs d_dw";
export var heroHeaderRight = "x_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "x_s8 d_gr d_cw";
export var heroParagraphCenter = "x_gs d_gs d_cw d_dw";
export var heroParagraphRight = "x_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "x_s9 d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "x_tb d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "x_tc d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "x_td d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "x_tf d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "x_tg y_tg";
export var heroExceptionNormal = "x_th y_th";
export var heroExceptionLarge = "x_tj y_tj";
export var Title1Small = "x_tk y_tk y_tr y_ts";
export var Title1Normal = "x_tl y_tl y_tr y_tt";
export var Title1Large = "x_tm y_tm y_tr y_tv";
export var BodySmall = "x_tn y_tn y_tr y_tL";
export var BodyNormal = "x_tp y_tp y_tr y_tM";
export var BodyLarge = "x_tq y_tq y_tr y_tN";